// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["fWfRXSZXg", "scVsRhEmK"];

const variantClassNames = {fWfRXSZXg: "framer-v-1nhgaut", scVsRhEmK: "framer-v-1yx386i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "fWfRXSZXg", "Variant 3": "scVsRhEmK"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "fWfRXSZXg", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "fWfRXSZXg", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FqvEK", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1nhgaut", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fWfRXSZXg"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({scVsRhEmK: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FqvEK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FqvEK .framer-1304lue { display: block; }", ".framer-FqvEK .framer-1nhgaut { height: 60px; overflow: visible; position: relative; width: 632px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 632
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"scVsRhEmK":{"layout":["fixed","fixed"]}}}
 */
const Framerwm_DnoTuj: React.ComponentType<Props> = withCSS(Component, css, "framer-FqvEK") as typeof Component;
export default Framerwm_DnoTuj;

Framerwm_DnoTuj.displayName = "Button";

Framerwm_DnoTuj.defaultProps = {height: 60, width: 632};

addPropertyControls(Framerwm_DnoTuj, {variant: {options: ["fWfRXSZXg", "scVsRhEmK"], optionTitles: ["Variant 1", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerwm_DnoTuj, [])